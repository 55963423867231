import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['leaguesOptions', 'teamsOptions']

  change () {
    this.showTeams(this.selectedLeagueLabel)
    this.teamsOptionsTarget.selectedIndex = -1
  }

  showTeams (selectedGroupLabel) {
    Array.from(this.leagueOptionGroups).forEach((el) => {
      el.classList.toggle('option--current', selectedGroupLabel === el.label)
    })
  }

  get leagueOptionGroups () {
    return this.teamsOptionsTarget.getElementsByTagName('optgroup')
  }

  get selectedLeagueLabel () {
    const leagues = this.leaguesOptionsTarget
    return leagues.options[leagues.selectedIndex].label
  }
}
