import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['modal']

  activate () {
    this.modalTarget.classList.toggle('is-active')
  }

  dismiss () {
    this.modalTarget.classList.toggle('is-active')
  }
}
